import * as React from "react"
import { Helmet } from "react-helmet"
import { Layout, Review } from "../components"
import LogoColorful from "../assets/papelzinho-logo-colorful.png"
import { useAppSelector } from "../app/hooks"

const IndexPage: React.FC = () => {
  const language = useAppSelector((state: any) => state.language.config)
  const texts = language.Home

  return (
    <>
      <Layout noHeader={true}>
        <Helmet>
          <title>Papelzinho ™</title>
        </Helmet>
        <header className="p-5">
          <img
            src={LogoColorful}
            alt="Papelzinho logo"
            width={150}
            height={27}
          />
        </header>
        <section className="gradient-bg px-5 py-5 text-light">
          <h1 className="mb-0 font-weight-bold">{texts.slogan}</h1>
        </section>

        <section className="px-5 pt-5 text-secondary">
          <div>
            <p>
              {texts.description[0]}{" "}
              <span role="img" aria-label="LOL">
                😂
              </span>
            </p>

            <p>{texts.description[1]}</p>

            <p>
              <span className="font-weight-bolder">{texts.description[2]}</span>
              <br />
              {texts.description[3]}
            </p>

            <p>{texts.description[4]}</p>

            <p>
              {texts.description[5]}{" "}
              <a
                className="email font-weight-bolder"
                href="https://api.whatsapp.com/message/4ZGE273VAQXKP1?autoload=1&app_absent=0"
              >
                Whatsapp.
              </a>
            </p>
          </div>
        </section>

        <section className="px-5 pt-4 text-secondary">
          <div className="row">
            <div className="col">
              <h3 className="text-dark">{texts.rating}</h3>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-2 mt-3">
            {texts.reviews.map((review, index) => (
              <div className="col" key={index}>
                <Review title={review.title} body={review.body} />
              </div>
            ))}
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage
